import S from "./style.module.scss";
import { CopyToClipboard } from "react-copy-to-clipboard";
import KakaoShare from "../kakaoShare/kakaoShare";
import TwitterShare from "../twitterShare/twitterShare";
import { url, kakao_desc, twitter_desc } from "../../common/constants";

const Home = () => {
  const copyAlert = () => {
    alert("링크 복사 완료!");
  };

  const goToTest = () => {
    window.location.href = "/test";
  };

  return (
    <div className={S.home_wrapper}>
      <div className={S.content_wrapper}>
        <img className={S.cover_img} src="img/cover.png" alt="logo" />
        <div className={S.start_btn_wrapper} onClick={goToTest}>
          시작하기
        </div>
        <div className={S.share_txt}>테스트 공유하기</div>
        <div className={S.share_wrapper}>
          <KakaoShare
            desc={kakao_desc}
            imgURL="https://mindmingle-assets.s3.ap-northeast-2.amazonaws.com/cover.png"
          />
          <TwitterShare desc={twitter_desc} />
          <div className={S.share_item} onClick={copyAlert}>
            <CopyToClipboard text={url}>
              <img className={S.share_img} src="/img/copy_link.png" alt="img" />
            </CopyToClipboard>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
