import Result from "../../common/api/result";
import S from "./style.module.scss";
import Button from "@mui/material/Button";
import { CopyToClipboard } from "react-copy-to-clipboard";
import KakaoShare from "../kakaoShare/kakaoShare";
import YouTube from "react-youtube";
import TwitterShare from "../twitterShare/twitterShare";
import { result_title } from "../../common/constants";

const EXPERT_URL =
  "https://m.expert.naver.com/expert/profile/home?storeId=100043222";
const YOUTUBE_URL = "https://www.youtube.com/@mind_mingle";
const INSTAGRAM_URL = "https://www.instagram.com/mbti_mind_mingle/";

const Results = ({ match }) => {
  const url = "https://eitest.mindmingle.kr/";
  const { type } = match.params;
  const result = Result[type];

  const goToHome = () => {
    window.location.href = "/";
  };

  const openURL = (url) => {
    window.open(url, "_blank");
  };

  if (!result) {
    return (
      <div className={S.empty_wrapper}>
        <div className={S.empty_txt}>존재하지 않는 결과입니다.</div>
        <Button
          style={{ width: "200px" }}
          variant="outlined"
          color="primary"
          onClick={goToHome}
        >
          돌아가기
        </Button>
      </div>
    );
  }
  const copyAlert = () => {
    alert("링크 복사완료!");
  };
  return (
    <div className={S.results_wrapper}>
      <div className={S.content_wrapper}>
        <img className={S.logo_img} src="/img/logo_black.png" alt="logo" />
        <div className={S.purple_txt}>{result_title}</div>
        <div className={S.title_txt}>
          {result.title} <span className={S.bold_txt}>{result.type}</span>
        </div>
        <img
          className={S.result_img}
          src={`/img/result_${result.result}.png`}
          alt="img"
        />
        <img
          style={{ margin: "0 0 3rem 0" }}
          className={S.result_img}
          src={`/img/type_${result.result}.png`}
          alt="img"
        />
        <div className={S.share_txt}>내 결과 공유하기</div>
        <div className={S.share_wrapper}>
          <KakaoShare
            desc={`나는 ${result.type} "${result.title}"!`}
            imgURL={`https://mindmingle-assets.s3.ap-northeast-2.amazonaws.com/result_${result.result}.png`}
            type={result.result}
          />
          <TwitterShare
            desc={`나는 ${result.type} "${result.title}"!

#mbti #mbti테스트 #외향내향테스트`}
            type={result.result}
          />
          <div className={S.share_item} onClick={copyAlert}>
            <CopyToClipboard text={url + result.result}>
              <img className={S.share_img} src="/img/copy_link.png" alt="img" />
            </CopyToClipboard>
          </div>
        </div>
        <img className={S.detail_img} src="/img/detail.png" alt="img" />
        <img
          style={{ margin: "3rem 0" }}
          className={S.result_img}
          src={`/img/text_${result.result}.png`}
          alt="img"
        />
        <div className={S.example_wrapper}>
          <div className={S.title_txt}>나와 비슷한 성향을 가진 대표 연예인</div>
          <hr className={S.divider} />
          <div className={S.img_wrapper}>
            <img
              className={S.example_img}
              src={`/img/ex1_${result.result}.png`}
              alt="img"
            />
            <img
              className={S.example_img}
              src={`/img/ex2_${result.result}.png`}
              alt="img"
            />
            <img
              className={S.example_img}
              src={`/img/ex3_${result.result}.png`}
              alt="img"
            />
          </div>
          <div className={S.summary_txt}>{result.summary}</div>
        </div>
        <div className={S.desc_wrapper}>
          <div className={S.description_txt}>{result.description}</div>
        </div>
        <div className={S.attribute_wrapper}>
          {Object.entries(result.attributes).map(([key, value]) => (
            <div className={S.item_wrapper} key={key}>
              <img className={S.item_img} src={`/img/mingle.png`} alt="img" />
              <div className={S.key_txt}>
                {key}: <span className={S.value_txt}>{value}</span>
              </div>
            </div>
          ))}
        </div>
        <img
          className={S.divider_img}
          src={`/img/divider_${result.result}.png`}
          alt="img"
        />
      </div>
      <img className={S.phrase_img} src="/img/phrase.png" alt="img" />
      <img className={S.logo_next_img} src="/img/logo_next.png" alt="img" />
      <div className={S.video_wrapper}>
        <YouTube
          videoId="7mxWz-o52DI"
          opts={{
            width: "100%",
            height: "auto",
          }}
        />
        <YouTube
          videoId="wmvM1UZ5Uu8"
          opts={{
            width: "100%",
            height: "auto",
          }}
        />
      </div>
      <div className={S.btns_wrapper}>
        <div className={S.desc_txt}>
          전체 선호지표 클리어/라이트/블러 세부 척도 확인 가능
        </div>
        <div className={S.btn_wrapper} onClick={() => openURL(EXPERT_URL)}>
          정식검사 하러가기
        </div>
        <div className={S.btn_wrapper} onClick={() => openURL(YOUTUBE_URL)}>
          마인드밍글 유튜브 채널 보러가기
        </div>
        <div className={S.btn_wrapper} onClick={() => openURL(INSTAGRAM_URL)}>
          마인드밍글 인스타그램
        </div>
        <img
          className={S.twinkle_mingle_img}
          src="/img/twinkle_mingle.png"
          alt="img"
        />
      </div>
      <div className={S.share_txt}>내 결과 공유하기</div>
      <div className={S.share_wrapper}>
        <KakaoShare
          desc={`나는 ${result.type} "${result.title}"!`}
          imgURL={`https://mindmingle-assets.s3.ap-northeast-2.amazonaws.com/result_${result.result}.png`}
        />
        <TwitterShare
          desc={`나는 ${result.type} "${result.title}"!

#mbti #mbti테스트 #외향내향테스트`}
          type={result.result}
        />
        <div className={S.share_item} onClick={copyAlert}>
          <CopyToClipboard text={url + result.result}>
            <img className={S.share_img} src="/img/copy_link.png" alt="img" />
          </CopyToClipboard>
        </div>
      </div>

      <div
        style={{ margin: "3rem 0" }}
        className={S.btn_wrapper}
        onClick={goToHome}
      >
        테스트 다시 하기 🔄
      </div>
    </div>
  );
};

export default Results;
