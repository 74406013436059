import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "../src/components/home/home";
import Results from "./components/results/results";
import GlobalStyle from "./common/css/globalStyle";
import Options from "./components/options/options";
const App = () => {
  return (
    <BrowserRouter>
      <GlobalStyle />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/test">
          <Options />
        </Route>
        <Route>
          <Route path="/result/:type" component={Results} />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default App;
