import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import S from "./style.module.scss";
import Questions from "../../common/api/questions";
import Button from "@mui/material/Button";
import { directions } from "../../common/constants";

const Options = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [scores, setScores] = useState({});
  const [showResults, setShowResults] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const history = useHistory();
  const currentQuestion = questions[currentQuestionIndex];
  const TOTAL_SLIDES = 10;

  useEffect(() => {
    const shuffle = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    };

    setQuestions(shuffle(Questions.slice()));
  }, []);

  const handleAnswerClick = (score) => {
    setSelectedAnswer(score);
  };

  const getTotalScore = () => {
    return Object.values(scores).reduce((a, b) => a + b, 0);
  };

  const getType = () => {
    let count = 0;
    let totalScore = getTotalScore();

    for (const score of Object.values(scores)) {
      if (totalScore > 0) {
        if (score >= 2 && score <= 4) {
          count += 1;
        }
      } else {
        if (score >= -4 && score <= -2) {
          count += 1;
        }
      }
    }

    if (count >= 4) {
      return "clear";
    } else if (count >= 2) {
      return "light";
    } else {
      return "blur";
    }
  };

  const makeResult = () => {
    setShowResults(true);

    const direction = getTotalScore() >= 0 ? directions[0] : directions[1];
    const degreeType = getType();

    setTimeout(() => {
      history.push(`/result/${degreeType}_${direction}`);
    }, 3000);
  };

  const handleNextClick = () => {
    setScores((prevScores) => {
      const category = currentQuestion.category;
      const score =
        selectedAnswer * (currentQuestion.type === "negative" ? -1 : 1);
      return { ...prevScores, [category]: (prevScores[category] || 0) + score };
    });

    if (currentQuestionIndex < Questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setSelectedAnswer(null);
    } else {
      makeResult();
    }
  };

  return (
    <div className={S.options_wrapper}>
      {!showResults ? (
        currentQuestion ? (
          <>
            <div className={S.slide_wrapper}>
              <span className={S.current_txt}>{currentQuestionIndex + 1}</span>
              <span className={S.default_txt}>/{TOTAL_SLIDES}</span>
            </div>
            <div className={S.content_wrapper}>
              <div className={S.question_txt}>{currentQuestion.question}</div>
              <div className={S.radio_wrapper}>
                {currentQuestion.answers.map((answer, index) => (
                  <>
                    <input
                      type="radio"
                      id={`option${index}`}
                      className={S.radio}
                      name={`question${currentQuestionIndex}`}
                      value={answer.score}
                      onChange={() => handleAnswerClick(answer.score)}
                      checked={selectedAnswer === answer.score}
                    />
                    <label htmlFor={`option${index}`} className={S.radio_label}>
                      {answer.content}
                    </label>
                  </>
                ))}
              </div>
              <Button
                style={{ width: "200px" }}
                variant="outlined"
                color="primary"
                disabled={selectedAnswer === null}
                onClick={handleNextClick}
              >
                다음 질문으로 가기
              </Button>
            </div>
          </>
        ) : (
          <p>Loading...</p>
        )
      ) : (
        <div className={S.loading_wrapper}>
          <img
            className={S.checking_img}
            src="img/checking.png"
            alt="checking-mingle"
          />
          <div className={S.loading}></div>
        </div>
      )}
    </div>
  );
};

export default Options;
