import { useEffect } from "react";
import S from "./style.module.scss";
import { kakao_share_title } from "../../common/constants";

const { Kakao } = window;

//props
//desc: string
//imgURL: string
//type: string

const KakaoShare = (props) => {
  const url = "https://eitest.mindmingle.kr/";
  const shareURL = props.type != undefined ? url + "result/" + props.type : url;

  useEffect(() => {
    Kakao.cleanup();
    Kakao.init("cf0c237955b8a1aec0a9d3c1995798dc");
    console.log(Kakao.isInitialized());
  }, []);

  const shareKakao = () => {
    Kakao.Share.sendDefault({
      objectType: "feed",
      content: {
        title: `${kakao_share_title}`,
        description: `${props.desc}`,
        imageUrl: `${props.imgURL}`,
        link: {
          webUrl: shareURL,
          mobileWebUrl: shareURL,
        },
      },
      buttons: [
        {
          title: "나도 테스트 하러가기",
          link: {
            webUrl: shareURL,
            mobileWebUrl: shareURL,
          },
        },
      ],
    });
  };

  return (
    <div className={S.kakao_share_wrapper} onClick={() => shareKakao()}>
      <img className={S.share_img} src="/img/kakaotalk.png" alt="img" />
    </div>
  );
};

export default KakaoShare;
