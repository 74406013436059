import S from "./style.module.scss";

//props
//desc: string
//type: string
const TwitterShare = (props) => {
  const shareTwitter = () => {
    const url = "https://eitest.mindmingle.kr/";
    const shareURL =
      props.type != undefined ? url + "result/" + props.type : url;
    window.open(
      "https://twitter.com/intent/tweet?text=" +
        encodeURIComponent(props.desc) +
        "&url=" +
        shareURL,
    );
  };

  return (
    <div className={S.twitter_share_wrapper} onClick={() => shareTwitter()}>
      <img className={S.share_img} src="/img/twitter.png" alt="img" />
    </div>
  );
};

export default TwitterShare;
