export const s3_baseURL =
  "https://mindmingle-assets.s3.ap-northeast-2.amazonaws.com/eitest/";

// home
export const url = "https://eitest.mindmingle.kr/";
export const cover_url = s3_baseURL + "cover.png";
export const kakao_desc = `정식검사 기반 외향(E)-내향(I) 제대로 쪼개줌!`;
export const twitter_desc = `나와 비슷한 외향성/내향성을 가진 연예인 알아보기 테스트!
    
#mbti #mbti테스트 #외향내향테스트`;

// options
export const directions = ["e", "i"];

// results
export const result_title = "내 외향-내향 척도는...";

// kakaoShare
export const kakao_share_title =
  "나는 슈퍼 외향? 반반 내향? 외향내향 레벨 TEST";
